.background-image {
  width: 100%;
  height: 100%;
  position: absolute;
  /* overflow-y: hidden; */
}

.row {
  background-image: url('https://blog.techo-bloc.com/hs-fs/hubfs/eva-dalle-de-patio-A00422_05_002_PPI.jpg?width=1200&name=eva-dalle-de-patio-A00422_05_002_PPI.jpg');
}

.card {
  opacity: 90%;
  margin-top: 4em;
  margin-bottom: 4em;
}

.card-img-top {
  height: 20vh;
  width: auto
}

.container-fluid {
  overflow-y: hidden;
}